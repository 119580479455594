import "../styles/application.scss"

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import VueI18Next from '@panter/vue-i18next';
import Backend from 'i18next-xhr-backend';
import axios from 'axios'
import VueStripeCheckout from 'vue-stripe-checkout';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Vue2Filters from 'vue2-filters'
import VueMask from 'v-mask'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Datepicker from 'vuejs-datepicker'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

import translations from '../translations'
import NetcertComponents from '../components/main.js'
import store from '../store/main'

library.add(faUserSecret, faSpinner, faSpinner, faCheck)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)
Vue.use(VueI18Next)
Vue.use(Vue2Filters)
Vue.use(VueMask)
Vue.use(VueToast)

Vue.component('datepicker', Datepicker)
Vue.component(VueCountdown.name, VueCountdown);

var VueCookie = require('vue-cookie');
Vue.use(VueCookie)

Vue.config.devtools = true

const stripeOptions = {
  key: 'pk_test_GyUJ1ZAle9eYtEWLLWPuPQRm',
  image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
  email: '{{email}}',
  locale: 'auto',
  currency: 'USD',
  billingAddress: false,
  panelLabel: 'Pay {{amount}}',
  allowRememberMe: false
}
Vue.use(VueStripeCheckout, stripeOptions);

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('currency', function (value) {
  return '$' + parseFloat(value).toFixed(2);
})

const Turbolinks = require("turbolinks")
Turbolinks.start()

i18next.use(LngDetector).use(Backend).init({
 detection: {
    order: ['querystring', 'cookie'],
    lookupQuerystring: 'locale',
    lookupCookie: 'locale'
  },
  fallbackLng: 'en',
  defaultNS: ["translation"],
  ns: ['frontend'],
  debug: true,
  backend: {
    loadPath: '/api/v1/locales/{{lng}}/{{ns}}.json',
  }
});

document.addEventListener('turbolinks:load', () => {

  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  axios.defaults.headers.common['Accept'] = 'application/json'

  Vue.prototype.$http = axios.create({
    baseURL: '/api/v1/'
  })

  const i18n = new VueI18Next(i18next);
  const app = new Vue({
    el: '#app',
    store,
    i18n: i18n,
    components: NetcertComponents,
    created: function () {
      this.setCurrentUser()
      this.setCurrentOrganization()
    },
    methods: {
      setCurrentUser() {
        this.$http.get('/profile').then(e => {
          this.$store.dispatch('SET_USER', e.data.data.attributes)
          this.$store.dispatch('SET_PAVR_USER', this.$store.state.user)
        }).catch(error => {
          // fail silently, no logged in user
        })
      },
      setCurrentOrganization() {
        this.$http.get('/current-organization').then(e => {
          this.$store.dispatch('SET_ORGANIZATION', e.data.organization)
        })
      }
    }
  })
})
